import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Chip,
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  Terrain as TerrainIcon,
  AttachMoney as AttachMoneyIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Category as CategoryIcon,
  NearMe as NearMeIcon,
  BlockOutlined as BlockOutlinedIcon,
  ShoppingCart as ShoppingCartIcon,
  PlayArrow as PlayArrowIcon,
  CheckCircle as CheckCircleIcon,
  DirectionsCar as DirectionsCarIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Hunt } from '../../types/Hunt';
import { UserHuntStatus } from '../../types/UserHunt';
import ViewDetailsDialog from './ViewDetailsDialog';
import { EllipsisTypography } from '../../components/common/EllipsisTypography';
import ReactGA from 'react-ga4';

const HuntCard: React.FC<{ hunt: Hunt & { userHuntStatus?: UserHuntStatus } }> = ({ hunt }) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    ReactGA.event({
        category: 'hunt',
        action: 'view_details',
        label: hunt.name,
        value: hunt.price ? Math.round(hunt.price * 100) : 0,
      });
      setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const getStatusRibbon = () => {
    switch (hunt.userHuntStatus) {
      case UserHuntStatus.Purchased:
        return (
          <Chip
            icon={<ShoppingCartIcon />}
            label={t('findHunts.purchased')}
            color="primary"
            sx={{
              position: 'absolute',
              top: 20,
              right: -50,
              transform: 'rotate(45deg)',
              paddingX: 5,
              zIndex: 1,
            }}
          />
        );
      case UserHuntStatus.InProgress:
        return (
          <Chip
            icon={<PlayArrowIcon />}
            label={t('findHunts.inProgress')}
            color="secondary"
            sx={{
              position: 'absolute',
              top: 20,
              right: -50,
              transform: 'rotate(45deg)',
              paddingX: 5,
              zIndex: 1,
            }}
          />
        );
      case UserHuntStatus.Finished:
        return (
          <Chip
            icon={<CheckCircleIcon />}
            label={t('findHunts.completed')}
            color="success"
            sx={{
              position: 'absolute',
              top: 20,
              right: -50,
              transform: 'rotate(45deg)',
              paddingX: 5,
              zIndex: 1,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Card sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        opacity: hunt.enabled ? 1 : 0.6,
        position: 'relative',
        overflow: 'hidden',
      }}>
        <Box sx={{ position: 'relative' }}>
          {!hunt.enabled && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                zIndex: 1,
              }}
            >
              <Chip
                icon={<BlockOutlinedIcon />}
                label={t('findHunts.disabled')}
                color="error"
                sx={{
                  backgroundColor: 'red',
                  paddingX: 3,
                }}
              />
            </Box>
          )}
          {getStatusRibbon()}
          <CardMedia
            component="img"
            sx={{ height: 200, objectFit: 'cover' }}
            image={hunt.imageUrl || '/path/to/default/image.jpg'}
            alt={hunt.name}
          />
        </Box>
        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="div" align="center">
              {hunt.name}
            </Typography>
            <EllipsisTypography
              align="center"
              text={hunt.description}
              mobileLineLimit={3}
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2 }}
            />
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                  }}
                >
                  <TerrainIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">{hunt.locationAreaName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <NearMeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    {hunt.distanceToUserMiles !== undefined
                      ? `${Math.round(hunt.distanceToUserMiles)} ${t('findHunts.milesAway')}`
                      : t('findHunts.distanceUnknown')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <CategoryIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">{hunt.huntItems?.length ? hunt.huntItems.length : hunt.fakeHuntItemCount} {t('findHunts.items')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {hunt.totalDistanceMiles !== undefined && hunt.totalDistanceMiles <= 3 ? (
                      <DirectionsWalkIcon fontSize="small" sx={{ mr: 1 }} />
                    ) : (
                      <DirectionsCarIcon fontSize="small" sx={{ mr: 1 }} />
                    )}
                  <Typography variant="body2">
                    {hunt.totalDistanceMiles !== undefined
                      ? `${Math.round(hunt.totalDistanceMiles)} ${t('findHunts.milesLong')}`
                      : t('findHunts.distanceUnknown')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">{'~'} {hunt.estimatedTimeHours}  {t('findHunts.hours')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <AttachMoneyIcon fontSize="small" sx={{ mr: 0.5 }} />
                  <Typography
                    variant="body2"
                    fontSize="1rem"
                    marginTop={-0.25}
                    sx={Number(hunt.price) === 0 ? { color: 'green', fontWeight: 'bold' } : undefined}
                  >
                    {Number(hunt.price) === 0 ? t('findHunts.free') : hunt.price}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 'auto' }}>
            { hunt.userHuntStatus === UserHuntStatus.InProgress ? (
              <Button variant="contained" color="secondary" fullWidth onClick={() => navigate(`/activehunt/${hunt.id}`)}>
                {t('findHunts.continueHunt')}
              </Button>
            ) : hunt.userHuntStatus ? (
              <Button variant="contained" fullWidth onClick={() => navigate('/myhunts')}>
                {t('findHunts.viewMyHunts')}
              </Button>
            ) : (
              <Button variant="contained" fullWidth onClick={handleOpenDialog} startIcon={<ShoppingCartIcon />}>
                {t('findHunts.viewDetails')}
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      <ViewDetailsDialog
        hunt={hunt}
        open={isDialogOpen}
        onClose={handleCloseDialog}
      />
    </>
  );
};

export default HuntCard;