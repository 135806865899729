import React, { useState, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Grid,
  Box,
  Alert,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material';
import {
  Close as CloseIcon,
  AccessTime as AccessTimeIcon,
  Terrain as TerrainIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Category as CategoryIcon,
  NearMe as NearMeIcon,
  LocalOffer as LocalOfferIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Map as MapIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Hunt } from '../../types/Hunt';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useAuth } from '../../context/AuthContext';
import { useStripePurchase } from '../../hooks/useStripePurchase';
import { useNavigate } from 'react-router-dom';
import HuntLocationMap, { LocationPin } from '../../components/common/HuntLocationMap';
import { checkCouponCode } from '../../services/hunt/checkCouponCode';
import ReactGA from 'react-ga4';
import PaymentButton from './PaymentButton';

interface ViewDetailsDialogProps {
  hunt: Hunt;
  open: boolean;
  onClose: () => void;
}

const ViewDetailsDialog: React.FC<ViewDetailsDialogProps> = ({ hunt, open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { isAuthenticated } = useAuth();
  const token = localStorage.getItem('token');
  const { error, initiateCheckout } = useStripePurchase();
  const navigate = useNavigate();
  const [mapOpen, setMapOpen] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [appliedCouponCode, setAppliedCouponCode] = useState('');
  const [displayPrice, setDisplayPrice] = useState(hunt.price);

  const completePurchase = async () => {
    ReactGA.event({
        category: 'hunt',
        action: 'begin_checkout',
        label: hunt.name,
        value: hunt.price ? Math.round(hunt.price * 100) : 0, // Convert to cents for integer value
      });
    await initiateCheckout({ id: hunt.id, type: 'hunt'}, appliedCouponCode);
    if (error) {
      console.error('Purchase error:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleApplyCode = async () => {
    // Call backend to validate coupon code
    // If valid, set hunt price to 0 and re-render
    // If not valid, clear the coupon code and show "Invalid coupon code"
    const isValid = await checkCouponCode(hunt.id, couponCode);
    if (isValid) {
      setDisplayPrice(0);
      setAppliedCouponCode(couponCode);
    } else {
      setCouponCode('');
      setDisplayPrice(hunt.price);
    }
  };

  const handleBuyNow = async () => {
    if (!isAuthenticated || !token) {
      navigate('/login', {
        state: {
          message: t('findHunts.loginToPurchase'),
          completePurchaseAfterLogin: true,
          itemId: hunt.id,
          itemType: 'hunt',
          couponCode: appliedCouponCode,
        }
      });
    } else {
      await completePurchase();
    }
  };

  const handleViewLocation = useCallback(() => {
    setMapOpen(true);
  }, []);

  const handleCloseMap = useCallback(() => {
    setMapOpen(false);
  }, []);

  const InfoItem: React.FC<{ icon: React.ReactNode; text: string; align?: 'left' | 'right' }> = ({ icon, text, align = 'left' }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }}>
      {align === 'left' && <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>{icon}</Box>}
      <Typography variant="body2">
        {text}
      </Typography>
      {align === 'right' && <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>{icon}</Box>}
    </Box>
  );

  const additionalPins: LocationPin[] = useMemo(() => {
    return hunt.huntItems?.reduce((validPins, item) => {
      const latitude = Number(item.latitude);
      const longitude = Number(item.longitude);
      if (latitude !== 0 && longitude !== 0) {
        validPins.push({
          latitude,
          longitude,
          title: item.title,
          objectId: item.id ?? 0,
          status: 'not_started' as const,
        });
      }
      return validPins;
    }, [] as LocationPin[]) ?? [];
  }, [hunt.huntItems]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ pr: 6 }} textAlign={'center'}>
        {hunt.name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>

        {/* Hunt Image and Description */}
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <img
                src={hunt.imageUrl || '/api/placeholder/400/300'}
                alt={hunt.name}
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '300px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
            </Box>
            <Typography variant="body2" paragraph align="center" sx={{ mb: 1 }}>
              {hunt.description}
            </Typography>
          </Grid>

          {/* Purchase Hunt Button */}
          <Grid item xs={12}>
            <PaymentButton
                handleBuyNow={handleBuyNow}
                isMobile={isMobile}
                displayPrice={displayPrice}
                t={t}
            />
            <Alert
              severity="info"
              sx={{
                mb: 0,
                mt: 2,
                textAlign: 'center',
                justifyContent: 'center',
                borderStyle: 'dashed',
                borderWidth: '1px',
                borderColor: "#faa",
              }}
            >
              {t('findHunts.youCanStartLater')}
            </Alert>

          </Grid>

          {/* Hunt Information Icons & Details*/}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 2, mb: 1 }}>
              <Grid container spacing={2}>

                {/* Left column */}
                <Grid item xs={6}>
                  <InfoItem icon={<TerrainIcon />} text={`${hunt.locationAreaName}`} />
                  <InfoItem icon={<CategoryIcon />} text={`${hunt.huntItems?.length ? hunt.huntItems.length : hunt.fakeHuntItemCount} ${t('findHunts.items')}`} />
                  <InfoItem
                    icon={<AccessTimeIcon />}
                    text={`~ ${hunt.estimatedTimeHours} ${t('findHunts.hours')}`}
                  />
                </Grid>

                {/* Right column */}
                <Grid item xs={6}>
                <InfoItem
                    icon={<NearMeIcon />}
                    text={
                      hunt.distanceToUserMiles !== undefined
                        ? `${Math.round(hunt.distanceToUserMiles)} ${t('findHunts.milesAway')}`
                        : t('findHunts.distanceUnknown')
                    }
                  />
                  <InfoItem
                    icon={<DirectionsWalkIcon />}
                    text={
                      hunt.totalDistanceMiles !== undefined
                        ? `${Math.round(hunt.totalDistanceMiles)} ${t('findHunts.milesLong')}`
                        : t('findHunts.distanceUnknown')
                    }
                  />
                    <Button
                      startIcon={<MapIcon />}
                      onClick={handleViewLocation}
                      variant="text"
                      color="primary"
                      fullWidth={isMobile}
                      sx={{
                        left: 0,
                        height: '32px',
                        textAlign: 'left',
                        mb: 0,
                        pl: 0,
                        ml: 0.5,
                        textTransform: 'none',
                        textDecoration: 'underline',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {t('findHunts.viewLocation')}
                    </Button>


                </Grid>

              </Grid>
            </Paper>
          </Grid>

          {/* Coupon Code */}
          <Grid item xs={12}>
            {hunt.couponCode && (
              <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <TextField
                  label={t('findHunts.couponCode')}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  size="small"
                  sx={{
                    width: '50%',
                    '& .MuiInputLabel-root': {
                      fontSize: '0.875rem'
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: '50%' }}
                  onClick={handleApplyCode}
                >
                  {t('findHunts.applyCode')}
                </Button>
              </Box>
            )}
          </Grid>

          {/* Exclusive Coupons Blurb */}
          <Grid item xs={12}>
            <Paper elevation={1} sx={{ p: 2, mb: 1, mt: 0.5, bgcolor: theme.palette.info.main, justifyContent: 'center' }}>
              <Typography variant="h6" gutterBottom sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                <LocalOfferIcon sx={{ mr: 1, mt: 0.5 }} />
                {t('findHunts.exclusiveCoupons')}
              </Typography>
              <Typography variant="body2" textAlign={'center'} sx={{ mx: isMobile? 0 : 5}}>
                {t('findHunts.couponBlurb')}
              </Typography>
            </Paper>
          </Grid>

          {/* Included Items */}
          <Grid item xs={12}>
            <Paper elevation={1} sx={{ p: 1, mb: 3 }}>
            <Typography variant="h5" gutterBottom textAlign={'center'}>
              {t('findHunts.includedItems')}
            </Typography>
            <List dense>
              {hunt.huntItems?.map((item) => (
                <ListItem key={item.id}>
                  <ListItemIcon>
                    <CheckCircleOutlineIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
            </List>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <HuntLocationMap
        open={mapOpen}
        onClose={handleCloseMap}
        latitude={Number(hunt.latitude)}
        subtitle={hunt.name}
        showDistanceToUser={true}
        showUserLocation={true}
        drawLineFromUserLocation={true}
        longitude={Number(hunt.longitude)}
        additionalPins={additionalPins}
        doNotShowMapKey={false}
      />
    </Dialog>
  );
};

export default ViewDetailsDialog;