import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import imageCompression from 'browser-image-compression';
import { useIsMobile } from '../../hooks/useIsMobile';

interface ImageUploadProps {
  title: string;
  onImageCapture: (file: File | null) => void;
  existingImageUrl?: string;
}

const ImageUploadModal: React.FC<ImageUploadProps> = ({ title, onImageCapture, existingImageUrl }) => {
  const { t } = useTranslation();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (existingImageUrl) {
      setPreviewUrl(existingImageUrl);
    }
  }, [existingImageUrl]);

  const compressImage = async (file: File): Promise<File> => {
    if (file.size <= 3 * 1024 * 1024) {
      return file;
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Error compressing image:', error);
      return file;
    }
  };

  const validateAspectRatio = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        resolve(aspectRatio >= 1.2);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleImageCapture = async (file: File) => {
    const isValidRatio = await validateAspectRatio(file);
    if (!isValidRatio) {
      setError(t('imageUpload.aspectRatioError'));
      return;
    }

    setError(null);
    const compressedFile = await compressImage(file);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(compressedFile);
    onImageCapture(compressedFile);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        await handleImageCapture(acceptedFiles[0]);
      }
    },
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
    },
    multiple: false,
    noClick: true,
    noKeyboard: true
  });

  const handleRemoveImage = () => {
    setPreviewUrl(null);
    setError(null);
    onImageCapture(null);
  };

  return (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {previewUrl ? (
        <Box sx={{ position: 'relative', display: 'inline-block' }}>
          <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: isMobile ? '200px' : '100%' }} />
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton onClick={open} size="small" sx={{ bgcolor: 'background.paper', mr: 1 }}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleRemoveImage} size="small" sx={{ bgcolor: 'background.paper' }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed #ccc',
            borderRadius: 2,
            p: 4,
            cursor: 'pointer',
            '&:hover': {
              borderColor: 'primary.main',
            },
            backgroundColor: isDragActive ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
          }}
        >
          <input {...getInputProps()} />
          <Typography>
            {isDragActive
              ? t('imageUpload.dropHere')
              : t('imageUpload.dragDropOrClick')}
          </Typography>
          <Button
            variant="contained"
            onClick={open}
            sx={{ mt: 2 }}
          >
            {t('imageUpload.selectImage')}
          </Button>
        </Box>
      )}
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUploadModal;
