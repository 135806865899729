import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Business, Location, allowedBusinessTypes } from '../../../types/Business';
import { Coupon, NewCoupon } from '../../../types/Coupon';
import { HAWAIIAN_ISLANDS } from '../../../types/Location';
import { ImageUploadModal, ResponsiveTextField } from '../../../components/common';
import { createBusiness } from '../../../services/business/createBusiness';
import { updateBusiness } from '../../../services/business/updateBusiness';
import { TabPanel } from '../TabPanel';
import { ValidationErrorDialog } from '../../../components/common';
import { useGoogleMapsScript } from '../../../hooks/useGoogleMapsScript';
import { MapCoordinatesPicker } from '../../../components/common/MapCoordinatesPicker';
import { useIsMobile } from '../../../hooks/useIsMobile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FeatureNotImplementedDialog from '../../../components/common/FeatureNotImplemented';
import { CouponModal } from './CouponModal';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import InfoIcon from '@mui/icons-material/Info';
import { StateSelector } from '../../../components/common/StateSelector';

interface BusinessFormModalProps {
  open: boolean;
  onClose: () => void;
  onBusinessSaved: () => void;
  business?: Business | null;
}

export const BusinessFormModal: React.FC<BusinessFormModalProps> = ({
  open,
  onClose,
  onBusinessSaved,
  business
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Partial<Business>>({});
  const [locationData, setLocationData] = useState<Partial<Location>>({});
  const [tabValue, setTabValue] = useState(0);
  const [businessImageFile, setBusinessImageFile] = useState<File | null>(null);
  const [locationImageFile, setLocationImageFile] = useState<File | null>(null);
  const [businessImagePreview, setBusinessImagePreview] = useState<string | undefined>(undefined);
  const [locationImagePreview, setLocationImagePreview] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationError, setShowValidationError] = useState(false);
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const isMobile = useIsMobile();

  const { isLoaded, loadError } = useGoogleMapsScript();

  // Add a new state for the FeatureNotImplementedDialog
  const [showFeatureNotImplemented, setShowFeatureNotImplemented] = useState(false);

  // Add these new state variables
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (isLoaded && !geocoder) {
      setGeocoder(new google.maps.Geocoder());
    }
  }, [isLoaded, geocoder]);

  const isEditMode = !!business;

  useEffect(() => {
    if (business) {
      setFormData({ ...business });
      if (business.locations && business.locations.length > 0) {
        const location = business.locations[0];
        setLocationData({ ...location });
      }
      setCoupons(business.coupons || []);
      setBusinessImagePreview(business.imageUrl);
      setLocationImagePreview(business.locations?.[0]?.imageUrl);
    } else {
      clearForm();
    }
  }, [business]);

  const clearForm = () => {
    setFormData({});
    setLocationData({});
    setTabValue(0);
    setBusinessImageFile(null);
    setLocationImageFile(null);
    setBusinessImagePreview(undefined);
    setLocationImagePreview(undefined);
    setCoupons([]);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocationTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleBusinessImageCapture = (file: File | null) => {
    setBusinessImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setBusinessImagePreview(e.target?.result as string);
      reader.readAsDataURL(file);
    } else {
      setBusinessImagePreview(undefined);
    }
  };

  const handleLocationImageCapture = (file: File | null) => {
    setLocationImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setLocationImagePreview(e.target?.result as string);
      reader.readAsDataURL(file);
    } else {
      setLocationImagePreview(undefined);
    }
  };

  const validateForm = (): boolean => {
    const errors: string[] = [];

    if (!formData.name) errors.push('Business name is required');
    if (!formData.contactEmail) errors.push('Business contact email is required');
    if (!locationData.name) errors.push('Location name is required');

    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateForm()) {
      setShowValidationError(true);
      return;
    }

    try {
      setIsLoading(true);
      const businessData = {
        ...formData,
        locations: [{
          ...locationData,
        }],
        coupons: coupons, // Add this line to include coupons
      };
      const formDataToSend = new FormData();
      formDataToSend.append('business', JSON.stringify(businessData));

      if (businessImageFile) {
        formDataToSend.append('businessImage', businessImageFile);
      }
      if (locationImageFile) {
        formDataToSend.append('locationImage', locationImageFile);
      }

      if (isEditMode && business) {
        await updateBusiness(business.id, formDataToSend);
      } else {
        await createBusiness(formDataToSend);
      }
      onBusinessSaved();
      clearForm();
      onClose();
    } catch (error) {
      console.error(`Error ${isEditMode ? 'updating' : 'creating'} business:`, error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setLocationData((prev) => ({ ...prev, latitude: lat, longitude: lng }));
  };

  const getAddressString = () => {
    return `${locationData.streetAddress}, ${locationData.city}, ${locationData.state} ${locationData.zipCode}`;
  };

  const handleCreateCoupon = () => {
    handleOpenCouponModal();
  };

  const handleEditCoupon = (couponId: number) => {
    const coupon = coupons.find((c) => c.id === couponId);
    if (coupon) {
      handleOpenCouponModal(coupon);
    }
  };

  const handleDeleteCoupon = (couponId: number) => {
    setCouponToDelete(couponId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteCoupon = () => {
    if (couponToDelete !== null) {
      const updatedCoupons = coupons.filter((coupon) => coupon.id !== couponToDelete);
      setCoupons(updatedCoupons);

      if (business) {
        setFormData((prevData) => ({
          ...prevData,
          coupons: prevData.coupons?.filter((coupon) => coupon.id !== couponToDelete) || [],
        }));
      }
    }
    setDeleteConfirmationOpen(false);
    setCouponToDelete(null);
  };

  const cancelDeleteCoupon = () => {
    setDeleteConfirmationOpen(false);
    setCouponToDelete(null);
  };

  const handleOpenCouponModal = (coupon: Coupon | null = null) => {
    setSelectedCoupon(coupon);
    setCouponModalOpen(true);
  };

  const handleCloseCouponModal = () => {
    setSelectedCoupon(null);
    setCouponModalOpen(false);
  };

  const handleSaveCoupon = (coupon: Coupon | NewCoupon) => {
    if ('id' in coupon) {
      // Update existing coupon
      const updatedCoupons = coupons.map((c) => (c.id === coupon.id ? coupon : c));
      setCoupons(updatedCoupons);
    } else {
      // Add new coupon
      const newCoupon: Coupon = {
        ...coupon,
        id: Date.now(), // Use a temporary ID
        businessId: business?.id || 0, // Use the current business ID or 0 if not available
      };
      setCoupons([...coupons, newCoupon]);
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <>
    <Dialog
      open={open}
      onClose={() => {
        clearForm();
        onClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{isEditMode ? t('admin.businesses.editBusiness') : t('admin.businesses.createBusiness')}</DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'relative' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="business form tabs"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label={t('admin.businesses.businessInfo')} />
                <Tab label={t('admin.businesses.locationInfo')} />
                <Tab label={t('admin.businesses.coupons')} />
              </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ResponsiveTextField
                  name="name"
                  label={t('admin.businesses.name')}
                  value={formData.name || ''}
                  onChange={handleTextChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ResponsiveTextField
                  name="businessType"
                  select
                  label={t('admin.businesses.businessType')}
                  value={formData.businessType || 'Other'}
                  onChange={handleTextChange}
                  fullWidth
                  required
                >
                  {allowedBusinessTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </ResponsiveTextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <ResponsiveTextField
                  name="contactEmail"
                  label={t('admin.businesses.contactEmail')}
                  type="email"
                  value={formData.contactEmail || ''}
                  onChange={handleTextChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ResponsiveTextField
                  name="phoneNumber"
                  label={t('admin.businesses.phoneNumber')}
                  value={formData.phoneNumber || ''}
                  onChange={handleTextChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ResponsiveTextField
                  name="website"
                  label={t('admin.businesses.website')}
                  value={formData.website || ''}
                  onChange={handleTextChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ResponsiveTextField
                  name="description"
                  label={t('admin.businesses.description')}
                  value={formData.description || ''}
                  onChange={handleTextChange}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <ResponsiveTextField
                  name="welcomeMessage"
                  label={t('admin.businesses.welcomeMessage')}
                  value={formData.welcomeMessage || ''}
                  onChange={handleTextChange}
                  fullWidth
                  multiline
                  rows={3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={t('admin.businesses.welcomeMessageTooltip')}>
                          <IconButton size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* I don't think we need this anymore... but let's keep the code just in case */}
              {/*
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isVerified"
                      checked={formData.isVerified || false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={t('admin.businesses.isVerified')}
                />
              </Grid>
              */}
              <Grid item xs={12}>
                <ImageUploadModal
                  title={t('admin.businesses.businessImage')}
                  onImageCapture={handleBusinessImageCapture}
                  existingImageUrl={businessImagePreview}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ResponsiveTextField
                    name="name"
                    label={t('admin.businesses.locationName')}
                    value={locationData.name || ''}
                    onChange={handleLocationTextChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ResponsiveTextField
                    name="streetAddress"
                    label={t('admin.businesses.streetAddress')}
                    value={locationData.streetAddress || ''}
                    onChange={handleLocationTextChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ResponsiveTextField
                    name="city"
                    label={t('admin.businesses.city')}
                    value={locationData.city || ''}
                    onChange={handleLocationTextChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StateSelector
                    value={locationData.state || ''}
                    onChange={(value) => setLocationData((prev) => ({ ...prev, state: value }))}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ResponsiveTextField
                    name="zipCode"
                    label={t('admin.businesses.zipCode')}
                    value={locationData.zipCode || ''}
                    onChange={handleLocationTextChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <ResponsiveTextField
                    select
                    name="areaName"
                    label={t('admin.locations.islandName')}
                    value={locationData.areaName || ''}
                    onChange={handleLocationTextChange}
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Tooltip title={t('admin.locations.islandNameTooltip')}>
                            <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                              <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  >
                    {HAWAIIAN_ISLANDS.map((island) => (
                      <MenuItem key={island} value={island}>
                        {island}
                      </MenuItem>
                    ))}
                  </ResponsiveTextField>
                </Grid>
                <Grid item xs={12}>
                  <ResponsiveTextField
                    name="description"
                    label={t('admin.businesses.locationDescription')}
                    value={locationData.description || ''}
                    onChange={handleLocationTextChange}
                    fullWidth
                    multiline
                    rows={4}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <MapCoordinatesPicker
                    latitude={Number(locationData.latitude)}
                    longitude={Number(locationData.longitude)}
                    onCoordinatesChange={handleCoordinatesChange}
                    title={t('admin.businesses.locationCoordinates')}
                    getAddressString={getAddressString}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ImageUploadModal
                    title={t('admin.businesses.locationImage')}
                    onImageCapture={handleLocationImageCapture}
                    existingImageUrl={locationImagePreview}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleCreateCoupon}
                >
                  {t('admin.businesses.createCoupon')}
                </Button>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('admin.businesses.couponTitle')}</TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>{t('admin.businesses.couponCode')}</TableCell>
                        <TableCell>{t('admin.businesses.couponDescription')}</TableCell>
                      </>
                    )}
                    <TableCell>{t('admin.businesses.couponIsActive')}</TableCell>
                    <TableCell>{t('common.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coupons.length > 0 ? (
                    coupons.map((coupon) => (
                      <TableRow key={coupon.id}>
                        <TableCell>{coupon.title}</TableCell>
                        {!isMobile && (
                          <>
                            <TableCell>{coupon.couponCode}</TableCell>
                            <TableCell>{coupon.description}</TableCell>
                          </>
                        )}
                        <TableCell>
                          {coupon.isActive ? t('admin.businesses.couponActiveYes') : t('admin.businesses.couponActiveNo')}
                        </TableCell>
                        <TableCell>
                          <Box display="flex" flexDirection="row">
                            <IconButton onClick={() => handleEditCoupon(coupon.id)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteCoupon(coupon.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={isMobile ? 3 : 5} align="center">
                        <Typography variant="body2" sx={{ fontStyle: 'italic', fontSize: '0.9em' }}>
                          {t('admin.businesses.noCoupons')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                clearForm();
                onClose();
              }}
            >
              {t('common.cancel')}
            </Button>
            <Button type="submit" variant="contained" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : (isEditMode ? t('common.update') : t('common.create'))}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ValidationErrorDialog
        open={showValidationError}
        onClose={() => setShowValidationError(false)}
        errors={validationErrors}
      />
      <FeatureNotImplementedDialog
        open={showFeatureNotImplemented}
        onClose={() => setShowFeatureNotImplemented(false)}
      />
      <CouponModal
        open={couponModalOpen}
        onClose={handleCloseCouponModal}
        onSave={handleSaveCoupon}
        coupon={selectedCoupon || undefined}
        businessId={business?.id || 0} // Pass the business ID to the CouponModal
      />
      <ConfirmationDialog
        open={deleteConfirmationOpen}
        title={t('admin.coupons.deleteConfirmationTitle')}
        message={t('admin.coupons.deleteConfirmationMessage')}
        onConfirm={confirmDeleteCoupon}
        onCancel={cancelDeleteCoupon}
        confirmButtonText={t('common.delete')}
      />
    </>
  );
};