// frontend/src/pages/Admin/Businesses/index.tsx

import React from 'react';
import { useRole } from '../../../hooks/useRole';
import { Roles } from '../../../types/Roles';
import { BusinessUserPage } from './BusinessUserPage';
import { AdminUserPage } from './AdminUserPage';

export const Businesses: React.FC = () => {
  const role = useRole();

  if (role === Roles.Business) {
    return <BusinessUserPage />;
  }

  return <AdminUserPage />;
};
