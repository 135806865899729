import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Coupon, NewCoupon } from '../../../types/Coupon';
import { ResponsiveTextField } from '../../../components/common';
import InfoIcon from '@mui/icons-material/Info';

interface CouponModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (coupon: Coupon | NewCoupon) => void;
  coupon?: Coupon | NewCoupon;
  businessId: number;
}

const TooltipIconButton: React.FC<{ title: string }> = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip
      title={title}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
    >
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export const CouponModal: React.FC<CouponModalProps> = ({
  open,
  onClose,
  onSave,
  coupon,
  businessId,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<Partial<Coupon>>(getInitialFormData());
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (open) {
      if (coupon) {
        setFormData(coupon);
      } else {
        setFormData(getInitialFormData());
      }
    }
  }, [open, coupon]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'isActive' ? checked : value,
    }));
    validateForm();
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.title || formData.title.trim() === '' || formData.title.length < 5) {
      newErrors.title = t('admin.coupons.errors.titleRequired');
    }
    if (!formData.couponCode || formData.couponCode.trim() === '' || formData.couponCode.length < 5) {
      newErrors.couponCode = t('admin.coupons.errors.couponCodeRequired');
    }
    if (!formData.description || formData.description.trim() === '' || formData.description.length < 10) {
      newErrors.description = t('admin.coupons.errors.descriptionRequired');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      const couponToSave: Coupon | NewCoupon = coupon
        ? { ...formData as Coupon }
        : { ...formData as NewCoupon, businessId };
      onSave(couponToSave);
      onClose();
    }
  };

  const isFormValid = Object.keys(errors).length === 0;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {coupon ? t('admin.coupons.editCoupon') : t('admin.coupons.createCoupon')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <ResponsiveTextField
              name="title"
              label={t('admin.coupons.title')}
              value={formData.title || ''}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.title}
              helperText={errors.title}
              InputProps={{
                endAdornment: (
                  <TooltipIconButton title={t('admin.coupons.titleTooltip')} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ResponsiveTextField
              name="couponCode"
              label={t('admin.coupons.couponCode')}
              value={formData.couponCode || ''}
              onChange={handleChange}
              fullWidth
              required
              error={!!errors.couponCode}
              helperText={errors.couponCode}
              InputProps={{
                endAdornment: (
                  <TooltipIconButton title={t('admin.coupons.couponCodeTooltip')} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ResponsiveTextField
              name="description"
              label={t('admin.coupons.description')}
              value={formData.description || ''}
              onChange={handleChange}
              required
              error={!!errors.description}
              helperText={errors.description}
              fullWidth
              multiline
              rows={3}
              InputProps={{
                endAdornment: (
                  <TooltipIconButton title={t('admin.coupons.descriptionTooltip')} />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResponsiveTextField
              name="startDate"
              label={t('admin.coupons.startDate')}
              type="date"
              value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ResponsiveTextField
              name="endDate"
              label={t('admin.coupons.endDate')}
              type="date"
              value={formData.endDate ? new Date(formData.endDate).toISOString().split('T')[0] : ''}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.isActive || false}
                  onChange={handleChange}
                  name="isActive"
                  color="primary"
                />
              }
              label={<Typography>{t('admin.coupons.isActive')}</Typography>}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
        <Button onClick={handleSave} variant="contained" disabled={!isFormValid}>
          {coupon ? t('common.update') : t('common.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function getInitialFormData(): Partial<NewCoupon> {
  return {
    title: '',
    description: '',
    couponCode: '',
    isActive: true,
    startDate: new Date(),
    endDate: undefined,
  };
}
