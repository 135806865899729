import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, MenuItem, Paper, Card, CardContent, CardActions, IconButton, Tooltip, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getBusinesses } from '../../../services/business/getBusinesses';
import { createBusiness } from '../../../services/business/createBusiness';
import { updateBusiness } from '../../../services/business/updateBusiness';
import { Business, allowedBusinessTypes } from '../../../types/Business';
import { CouponModal } from './CouponModal';
import { ResponsiveTextField, ImageUploadModal } from '../../../components/common';
import { MapCoordinatesPicker } from '../../../components/common/MapCoordinatesPicker';
import { useGoogleMapsScript } from '../../../hooks/useGoogleMapsScript';
import { StateSelector } from '../../../components/common/StateSelector';
import { HAWAIIAN_ISLANDS } from '../../../types/Location';
import { Coupon, NewCoupon } from '../../../types/Coupon';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import { format } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAuth } from '../../../context/AuthContext';

const createBusinessSchema = (t: (key: string) => string) => z.object({
  name: z.string().min(3, { message: t('admin.businesses.validation.name') }),
  contactEmail: z.string().email({ message: t('common.validation.invalidEmail') }),
  phoneNumber: z.string().min(10, { message: t('admin.businesses.validation.phoneNumber') }),
  website: z.string().url({ message: t('common.validation.invalidUrl') }).optional().or(z.literal('')),
  description: z.string().min(10, { message: t('admin.businesses.validation.description') }),
  welcomeMessage: z.string().min(10, { message: t('admin.businesses.validation.welcomeMessage') }),
  businessType: z.enum(allowedBusinessTypes as [string, ...string[]])
    .default('Other')
    .transform(val => (!val || val === '') ? 'Other' : val),
  businessImage: z.any().optional(),
  location: z.object({
    name: z.string().min(3, { message: t('admin.businesses.validation.location.name') }),
    streetAddress: z.string().min(6, { message: t('admin.businesses.validation.location.streetAddress') }),
    city: z.string().min(3, { message: t('admin.businesses.validation.location.city') }),
    state: z.string().min(2, { message: t('admin.businesses.validation.location.state') }),
    zipCode: z.string().min(5, { message: t('admin.businesses.validation.location.zipCode') }),
    areaName: z.string().min(3, { message: t('common.validation.required') }),
    description: z.string().min(10, { message: t('admin.businesses.validation.location.description') }),
    latitude: z.union([z.number(), z.string()]).transform(Number),
    longitude: z.union([z.number(), z.string()]).transform(Number),
    locationImage: z.any().optional(),
  }),
});

type BusinessFormData = z.infer<ReturnType<typeof createBusinessSchema>>;

export const BusinessUserPage: React.FC = () => {
  const { t } = useTranslation();
  const [business, setBusiness] = useState<Business | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const businessSchema = createBusinessSchema(t);
  const [businessImageFile, setBusinessImageFile] = useState<File | null>(null);
  const [locationImageFile, setLocationImageFile] = useState<File | null>(null);
  const [businessImagePreview, setBusinessImagePreview] = useState<string | undefined>(undefined);
  const [locationImagePreview, setLocationImagePreview] = useState<string | undefined>(undefined);
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon | NewCoupon | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState<number | null>(null);
  const { user } = useAuth();
  const [originalValues, setOriginalValues] = useState<BusinessFormData | null>(null);

  const { isLoaded, loadError } = useGoogleMapsScript();

  const [hasUnsavedCouponChanges, setHasUnsavedCouponChanges] = useState(false);

  const [pendingCoupons, setPendingCoupons] = useState<(Coupon | NewCoupon)[]>([]);

  const { control, handleSubmit, formState: { errors, isValid, isDirty }, setValue, trigger, reset, getValues } = useForm<BusinessFormData>({
    resolver: zodResolver(businessSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      contactEmail: user?.email || '',
      phoneNumber: '',
      website: '',
      description: '',
      welcomeMessage: '',
      businessImage: null,
      businessType: '',
      location: {
        name: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        areaName: '',
        description: '',
        latitude: 0,
        longitude: 0,
        locationImage: null,
      },
    },
  });

  useEffect(() => {
    getBusinesses(1, 1).then((data) => {
      if (data.businesses.length > 0) {
        const fetchedBusiness = data.businesses[0];
        setBusiness(fetchedBusiness);

        // Create an object with all the form values
        const formValues = {
          name: fetchedBusiness.name,
          contactEmail: fetchedBusiness.contactEmail || '',
          phoneNumber: fetchedBusiness.phoneNumber || '',
          website: fetchedBusiness.website || '',
          description: fetchedBusiness.description || '',
          welcomeMessage: fetchedBusiness.welcomeMessage || '',
          businessImage: fetchedBusiness.imageUrl || null,
          businessType: fetchedBusiness.businessType || 'Other',
          location: {
            name: fetchedBusiness.locations?.[0]?.name || '',
            streetAddress: fetchedBusiness.locations?.[0]?.streetAddress || '',
            city: fetchedBusiness.locations?.[0]?.city || '',
            state: fetchedBusiness.locations?.[0]?.state || '',
            zipCode: fetchedBusiness.locations?.[0]?.zipCode || '',
            areaName: fetchedBusiness.locations?.[0]?.areaName || '',
            description: fetchedBusiness.locations?.[0]?.description || '',
            latitude: fetchedBusiness.locations?.[0]?.latitude || 0,
            longitude: fetchedBusiness.locations?.[0]?.longitude || 0,
            locationImage: fetchedBusiness.locations?.[0]?.imageUrl || null,
          },
        };

        reset(formValues);
        setOriginalValues(formValues);

        setBusinessImagePreview(fetchedBusiness.imageUrl);
        setLocationImagePreview(fetchedBusiness.locations?.[0]?.imageUrl);

        trigger();
      } else {
        // If no business exists, reset with default values (only prepopulate contact email)
        reset({
          contactEmail: user?.email || ''
        });
      }
      setIsLoading(false);
    });
  }, [reset, trigger, user]);


  const handleBusinessImageCapture = (file: File | null) => {
    setBusinessImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setBusinessImagePreview(e.target?.result as string);
      reader.readAsDataURL(file);
    } else {
      setBusinessImagePreview(undefined);
    }
  };

  const handleLocationImageCapture = (file: File | null) => {
    setLocationImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setLocationImagePreview(e.target?.result as string);
      reader.readAsDataURL(file);
    } else {
      setLocationImagePreview(undefined);
    }
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setValue('location.latitude', lat);
    setValue('location.longitude', lng);
    trigger(['location.latitude', 'location.longitude']);
  };

  const getAddressString = () => {
    const values = getValues();
    return `${values.location.streetAddress}, ${values.location.city}, ${values.location.state} ${values.location.zipCode}`;
  };

  const onSubmit = async (data: BusinessFormData) => {
    setIsLoading(true);

    const businessData = {
      ...data,
      locations: [{ ...data.location }],
      coupons: business ? business.coupons : pendingCoupons,
    };

    const formDataToSend = new FormData();
    formDataToSend.append('business', JSON.stringify(businessData));

    if (businessImageFile) {
      formDataToSend.append('businessImage', businessImageFile);
    }
    if (locationImageFile) {
      formDataToSend.append('locationImage', locationImageFile);
    }

    try {
      if (business) {
        await updateBusiness(business.id, formDataToSend);
      } else {
        await createBusiness(formDataToSend);
      }
      // Refetch the business data to update the state
      const updatedData = await getBusinesses(1, 1);
      if (updatedData.businesses.length > 0) {
        setBusiness(updatedData.businesses[0]);
        if (updatedData.businesses[0].locations && updatedData.businesses[0].locations.length > 0) {
          setValue('location.latitude', updatedData.businesses[0].locations[0].latitude || 0);
          setValue('location.longitude', updatedData.businesses[0].locations[0].longitude || 0);
        }
      }
    } catch (error) {
      console.error('Error saving business:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
      // Reset the form isDirty state by setting the original values TO the current values
      reset(getValues());
    }
    setHasUnsavedCouponChanges(false); // Reset after successful save
  };

  const handleOpenCouponModal = (coupon: Coupon | NewCoupon | null = null) => {
    setSelectedCoupon(coupon);
    setCouponModalOpen(true);
  };

  const handleCloseCouponModal = () => {
    setSelectedCoupon(null);
    setCouponModalOpen(false);
  };

  const handleSaveCoupon = (coupon: Coupon | NewCoupon) => {
    if (business) {
      // Existing business flow
      const updatedCoupons = 'id' in coupon
        ? business.coupons?.map((c) => (c.id === coupon.id ? coupon : c))
        : [...(business.coupons || []), { ...coupon, id: Date.now(), businessId: business.id }];
      setBusiness({ ...business, coupons: updatedCoupons });
    } else {
      // New business flow - use pendingCoupons
      const updatedCoupons = 'id' in coupon
        ? pendingCoupons.map((c) => ('id' in c && c.id === coupon.id ? coupon : c))
        : [...pendingCoupons, { ...coupon, id: Date.now() }];
      setPendingCoupons(updatedCoupons);
    }
    setHasUnsavedCouponChanges(true);
  };

  const handleRevertChanges = async () => {
    setIsLoading(true);
    try {
      const data = await getBusinesses(1, 1);
      if (data.businesses.length > 0) {
        const fetchedBusiness = data.businesses[0];
        setBusiness(fetchedBusiness);

        // Create an object with all the form values
        const formValues = {
          name: fetchedBusiness.name,
          contactEmail: fetchedBusiness.contactEmail || '',
          phoneNumber: fetchedBusiness.phoneNumber || '',
          website: fetchedBusiness.website || '',
          description: fetchedBusiness.description || '',
          welcomeMessage: fetchedBusiness.welcomeMessage || '',
          businessImage: fetchedBusiness.imageUrl || null,
          businessType: fetchedBusiness.businessType || 'Other',
          location: {
            name: fetchedBusiness.locations?.[0]?.name || '',
            streetAddress: fetchedBusiness.locations?.[0]?.streetAddress || '',
            city: fetchedBusiness.locations?.[0]?.city || '',
            state: fetchedBusiness.locations?.[0]?.state || '',
            zipCode: fetchedBusiness.locations?.[0]?.zipCode || '',
            areaName: fetchedBusiness.locations?.[0]?.areaName || '',
            description: fetchedBusiness.locations?.[0]?.description || '',
            latitude: fetchedBusiness.locations?.[0]?.latitude || 0,
            longitude: fetchedBusiness.locations?.[0]?.longitude || 0,
            locationImage: fetchedBusiness.locations?.[0]?.imageUrl || null,
          },
        };

        // Reset the form with the fetched values
        reset(formValues);

        setBusinessImagePreview(fetchedBusiness.imageUrl);
        setLocationImagePreview(fetchedBusiness.locations?.[0]?.imageUrl);
        setBusiness(fetchedBusiness);
      } else {
        reset();
        // If no business found, reset everything
        setValue('location.latitude', 0);
        setValue('location.longitude', 0);
        setBusinessImagePreview(undefined);
        setLocationImagePreview(undefined);
        setBusiness(null);
      }
    } catch (error) {
      console.error('Error fetching business data:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
    trigger();
  };

  const handleDeleteCoupon = (couponId: number) => {
    setCouponToDelete(couponId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDeleteCoupon = () => {
    if (couponToDelete !== null) {
      if (business) {
        const updatedCoupons = business.coupons?.filter((coupon) => coupon.id !== couponToDelete);
        setBusiness({ ...business, coupons: updatedCoupons });
      } else {
        const updatedCoupons = pendingCoupons.filter((coupon) =>
          'id' in coupon && coupon.id !== couponToDelete
        );
        setPendingCoupons(updatedCoupons);
      }
      setHasUnsavedCouponChanges(true);
    }
    setDeleteConfirmationOpen(false);
    setCouponToDelete(null);
  };

  const cancelDeleteCoupon = () => {
    setDeleteConfirmationOpen(false);
    setCouponToDelete(null);
  };

  const hasChanges = () => {
    const hasImageChanges = businessImageFile !== null || locationImageFile !== null;
    return isDirty || hasImageChanges || hasUnsavedCouponChanges;
  };

  const SubmitButtons = () => (
    <Grid container spacing={2} sx={{ mt: -2, mb: 4 }}>
      <Grid item xs={12} md={6}>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || !isValid || !hasChanges()}
          fullWidth
        >
          {isLoading ? <CircularProgress size={24} /> : (business ? t('admin.businesses.businessUser.updateBusiness') : t('admin.businesses.businessUser.createBusiness'))}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          variant="outlined"
          onClick={handleRevertChanges}
          disabled={!hasChanges()}
          fullWidth
        >
          {t('common.revertAllChanges')}
        </Button>
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ p: 2 }}>
      {!business && (
        <Typography variant="h6" sx={{ mb: 3, textAlign: 'center', fontStyle: 'italic' }}>
          {t('admin.businesses.businessUser.createBusinessToStart')}
        </Typography>
      )}

      <SubmitButtons />

      {/* Business Information Section */}
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>{t('admin.businesses.businessInfo')}</Typography>
        <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' } }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.name')}
                fullWidth
                required
                error={!!errors.name}
                defaultValue={originalValues?.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="contactEmail"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.contactEmail')}
                type="email"
                fullWidth
                required
                defaultValue={originalValues?.contactEmail}
                error={!!errors.contactEmail}
                helperText={errors.contactEmail?.message}
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.phoneNumber')}
                fullWidth
                required
                defaultValue={originalValues?.phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
            )}
          />
          <Controller
            name="website"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.website')}
                fullWidth
                error={!!errors.website}
                helperText={errors.website?.message}
                defaultValue={originalValues?.website}
              />
            )}
          />
          <Controller
            name="businessType"
            control={control}
            defaultValue={originalValues?.businessType || 'Other'}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                select
                label={t('admin.businesses.businessType')}
                fullWidth
                required
                error={!!errors.businessType}
                helperText={errors.businessType?.message}
              >
                {allowedBusinessTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </ResponsiveTextField>
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.description')}
                fullWidth
                required
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors.description?.message}
                defaultValue={originalValues?.description}
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Controller
            name="welcomeMessage"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.welcomeMessage')}
                fullWidth
                required
                multiline
                rows={3}
                error={!!errors.welcomeMessage}
                helperText={errors.welcomeMessage?.message}
                defaultValue={originalValues?.welcomeMessage}
                InputProps={{
                  endAdornment: (
                    <Tooltip title={t('admin.businesses.welcomeMessageTooltip')}>
                      <InfoOutlinedIcon color="action" sx={{ ml: 1, cursor: 'pointer' }} />
                    </Tooltip>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Controller
            name="businessImage"
            control={control}
            render={({ field }) => (
              <Box>
                <Tooltip title={t('admin.businesses.businessImageTooltip')}>
                  <InfoOutlinedIcon color="action" sx={{ mb: -10, ml: 1, cursor: 'pointer' }} />
                </Tooltip>
                <ImageUploadModal
                  title={t('admin.businesses.businessImage')}
                  onImageCapture={(file) => {
                    field.onChange(file);
                    handleBusinessImageCapture(file);
                  }}
                  existingImageUrl={businessImagePreview}
                />
              </Box>
            )}
          />
          {errors.businessImage && <Typography color="error">{t('validation.required')}</Typography>}
        </Box>
      </Paper>

      {/* Location Information Section */}
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>{t('admin.businesses.locationInfo')}</Typography>
        <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))' }}>
          <Controller
            name="location.name"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.locationName')}
                fullWidth
                required
                error={!!errors.location?.name}
                helperText={errors.location?.name?.message}
                defaultValue={originalValues?.location?.name}
              />
            )}
          />
          <Controller
            name="location.streetAddress"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.streetAddress')}
                fullWidth
                required
                error={!!errors.location?.streetAddress}
                helperText={errors.location?.streetAddress?.message}
                defaultValue={originalValues?.location?.streetAddress}
              />
            )}
          />
          <Controller
            name="location.city"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.city')}
                fullWidth
                required
                error={!!errors.location?.city}
                helperText={errors.location?.city?.message}
                defaultValue={originalValues?.location?.city}
              />
            )}
          />
          <Controller
            name="location.state"
            control={control}
            render={({ field }) => (
              <StateSelector
                {...field}
              />
            )}
          />
          <Controller
            name="location.zipCode"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.zipCode')}
                fullWidth
                required
                error={!!errors.location?.zipCode}
                helperText={errors.location?.zipCode?.message}
                defaultValue={originalValues?.location?.zipCode}
              />
            )}
          />
          <Controller
            name="location.areaName"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                select
                label={t('admin.locations.islandName')}
                fullWidth
                required
                error={!!errors.location?.areaName}
                helperText={errors.location?.areaName?.message}
                defaultValue={originalValues?.location?.areaName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title={t('admin.locations.islandNameTooltip')}>
                        <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                          <InfoIcon color="action" fontSize="small" sx={{ marginX: '-4px' }}/>
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              >
                {HAWAIIAN_ISLANDS.map((island) => (
                  <MenuItem key={island} value={island}>
                    {island}
                  </MenuItem>
                ))}
              </ResponsiveTextField>
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Controller
            name="location.description"
            control={control}
            render={({ field }) => (
              <ResponsiveTextField
                {...field}
                label={t('admin.businesses.locationDescription')}
                fullWidth
                required
                multiline
                rows={4}
                error={!!errors.location?.description}
                defaultValue={originalValues?.location?.description}
                helperText={errors.location?.description?.message}
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <MapCoordinatesPicker
            latitude={Number(getValues('location.latitude'))}
            longitude={Number(getValues('location.longitude'))}
            onCoordinatesChange={handleCoordinatesChange}
            title={t('admin.businesses.locationCoordinates')}
            getAddressString={getAddressString}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Controller
            name="location.locationImage"
            control={control}
            render={({ field }) => (
              <Box>
                <Tooltip title={t('admin.businesses.locationImageTooltip')}>
                  <InfoOutlinedIcon color="action" sx={{ mb: -10, ml: 2, cursor: 'pointer' }} />
                </Tooltip>
                <ImageUploadModal
                  title={t('admin.businesses.locationImage')}
                  onImageCapture={(file) => {
                    field.onChange(file);
                    handleLocationImageCapture(file);
                  }}
                  existingImageUrl={locationImagePreview}
                />
              </Box>
            )}
          />
          {errors.location?.locationImage && <Typography color="error">{t('validation.required')}</Typography>}
        </Box>
      </Paper>

      {/* Coupons Section */}
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>{t('admin.businesses.businessUser.yourCoupons')}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button
            variant="contained"
            onClick={() => handleOpenCouponModal()}
            startIcon={<AddIcon />}
            sx={{
              width: { xs: '100%', sm: '200px' }
            }}
          >
            {t('admin.businesses.createCoupon')}
          </Button>
        </Box>
        {(business?.coupons || pendingCoupons).length > 0 ? (
          <Grid container spacing={2}>
            {(business?.coupons || pendingCoupons).map((coupon) => {
              const now = new Date();
              const isStartDateValid = coupon.startDate ? new Date(coupon.startDate) <= now : true;
              const isEndDateValid = coupon.endDate ? new Date(coupon.endDate) >= now : true;
              const isCurrentlyActive = coupon.isActive && isStartDateValid && isEndDateValid;

              return (
                <Grid item xs={12} sm={6} md={4} key={'id' in coupon ? coupon.id : `temp-${Date.now()}`}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>{coupon.title}</Typography>
                      {coupon.startDate && (
                        <Typography variant="body2" sx={{ mt: 0.5 }}>
                          {t('admin.coupons.startDate')}: {format(new Date(coupon.startDate), 'PP')}
                        </Typography>
                      )}
                      {coupon.endDate && (
                        <Typography variant="body2" sx={{ mt: 0.5 }}>
                          {t('admin.coupons.endDate')}: {format(new Date(coupon.endDate), 'PP')}
                        </Typography>
                      )}
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                      <Chip
                        label={isCurrentlyActive ? t('admin.coupons.active') : t('admin.coupons.inactive')}
                        color={isCurrentlyActive ? "success" : "error"}
                        icon={isCurrentlyActive ? <CheckCircleIcon /> : <CancelIcon />}
                        size="small"
                      />
                      <Box>
                        <Tooltip title={t('admin.businesses.businessUser.editCoupon')}>
                          <IconButton onClick={() => handleOpenCouponModal(coupon)} size="small">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {'id' in coupon && (
                          <Tooltip title={t('admin.businesses.businessUser.deleteCoupon')}>
                            <IconButton onClick={() => handleDeleteCoupon(coupon.id)} size="small">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
            {t('admin.businesses.noCoupons')}
          </Typography>
        )}
      </Paper>

      <SubmitButtons />

      <CouponModal
        open={couponModalOpen}
        onClose={handleCloseCouponModal}
        onSave={handleSaveCoupon}
        coupon={selectedCoupon || undefined}
        businessId={business?.id || 0}
      />

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        title={t('admin.coupons.deleteConfirmationTitle')}
        message={t('admin.coupons.deleteConfirmationMessage')}
        onConfirm={confirmDeleteCoupon}
        onCancel={cancelDeleteCoupon}
        confirmButtonText={t('common.delete')}
      />
    </Box>
  );
};
