import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface ValidationErrorDialogProps {
  open: boolean;
  onClose: () => void;
  errors: string[];
}

export const ValidationErrorDialog: React.FC<ValidationErrorDialogProps> = ({
  open,
  onClose,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ bgcolor: 'error.light', color: 'white' }}>
        <Box display="flex" alignItems="center">
          <ErrorOutline sx={{ mr: 1 }} />
          <Typography variant="h6" component="span">
            {t('common.validationError')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <List sx={{ mt: 2 }}>
          {errors.map((error, index) => (
            <ListItem
              key={index}
              sx={{
                border: '1px dotted',
                borderColor: 'error.main',
                borderRadius: 1,
                mb: 1,
                p: 1,
              }}
            >
              <ListItemIcon>
                <ErrorOutline color="error" />
              </ListItemIcon>
              <ListItemText
                primary={error}
                primaryTypographyProps={{ color: 'error.main' }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};